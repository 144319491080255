import Vue from "vue"
import Vuex from "vuex"

import app from "../App"
import getters from "./getters"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app
  },
  state: {
    keyUser: ""
  },
  mutations: {
    setKeyUser(state, key) {
      state.keyUser = key
    }
  },
  actions: {
    fetchKeyUser() {
      //make the call
      //run setKeyUsern mutation
    }
  },
  getters
})
