import axios from "axios"
export class ServicoLogin {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Auth`
  }
  autenticar(usuario, senha) {
    let config = {
      withCredentials: false,
      crossdomain: true
    }
    return axios.post(this.urlServico, { Usuario: usuario, Senha: senha }, config)
  }
}
