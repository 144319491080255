<template>
  <v-form v-model="valid" ref="form">
    <v-container style="background-image: linear-gradient(#d9ada3, #eec3bb, #fad1c9)">
      <v-layout row wrap justify-center style="background-color:#fad1c9;">
        <v-flex xs10 md10>
          <v-img :src="image" contain></v-img>
          <v-text-field v-model="usuario" :rules="txtUsuarioRules" label="Usuário" required></v-text-field>
        </v-flex>
        <v-flex xs10 sm10>
          <v-text-field
            v-model="senha"
            :rules="txtSenhaRules"
            :type="show1 ? 'text' : 'password'"
            label="Senha"
            :append-outer-icon="show1 ? 'visibility' : 'visibility_off'"
            :append-outer-icon-cb="mostrarIcone"
          >
          </v-text-field>
        </v-flex>
        <v-card-text>
          <div class="text-md-center" v-show="card_text">{{ card_text }}</div>
          <v-progress-linear :value="valor_progress_bar" v-show="valor_progress_bar > 0"></v-progress-linear>
        </v-card-text>
        <v-btn name="botao-entrar" id="botao-entrar" @click="logar" color="info">Acessar</v-btn>
      </v-layout>
      <v-snackbar :color="'error'" v-model="snackbar" :timeout="timeout" :top="true">
        {{ text }}
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </v-form>
</template>
<script>
import { ServicoLogin } from "../../servicos/servicoLogin.js"
import store from "@/store/index"
const servicoLogin = new ServicoLogin()
export default {
  data: () => ({
    show1: false,
    valid: false,
    interval: null,
    usuario: null,
    senha: null,
    txtUsuarioRules: [v => !!v || "Usuário é obrigatório."],
    txtSenhaRules: [v => !!v || "Senha é obrigatória."],
    card_text: "",
    valor_progress_bar: 0,
    image: require("@/assets/Logo-B.png"),
    snackbar: false,
    text: "",
    timeout: 5000
  }),
  created() {
    localStorage.setItem("setKeyUser", null)
  },
  computed: {},
  methods: {
    logar: function() {
      if (this.$refs.form.validate()) {
        this.apresentaMensagem("Autenticando usuário.")
        this.autenticar()
      }
    },
    autenticar: function() {
      this.updateCount(50)
      servicoLogin
        .autenticar(this.usuario, this.senha)
        .then(res => {
          if (res.status === 200) {
            if (res.data.jwt != undefined) {
              store.commit("setKeyUser", res.data.jwt)
              localStorage.setItem("setKeyUser", res.data.jwt)
              this.$router.push({ name: "Dashboard" })
            } else {
              this.text = res.data[0].title + " - " + res.data[0].detail
              this.snackbar = true
              this.apresentaMensagem("Falha ao autenticar usuário.")
              clearInterval(this.interval)
            }
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log("Erro ao realizar login " + error)
          return Promise.reject(error)
        })
    },
    apresentaMensagem(mensagem) {
      return (this.card_text = mensagem)
    },
    updateCount(limiteBarra) {
      if (this.interval) {
        this.valor_progress_bar = 0

        clearInterval(this.interval)
      }

      this.interval = setInterval(() => {
        if (this.valor_progress_bar < limiteBarra) {
          this.valor_progress_bar += 1
        }
      }, 100)
    },
    mostrarIcone() {
      this.show1 = !this.show1
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>
